import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import NavbarAdmin from 'components/Navbars/NavbarAdmin.js'
import Footer from 'components/Footer/Footer'
import Sidebar from 'components/Sidebar/Sidebar.js'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js'

/* #################### COMPONENTS ############################# */
import ProfilesListAdmin from 'views/Admin/ProfilesListAdmin.js'
import Dashboard from 'views/Dashboard/DashboardAdminUser.js'
import Accounts from 'views/Admin/AcounntsListAdmin.js'
import States from 'views/Admin/StatesListAdmin.js'
import Cities from 'views/Admin/CitiesListAdmin.js'
import Countries from 'views/Admin/CountriesListAdmin.js'
import Tests from 'views/Admin/TestListAdmin.js'
import MyData from 'views/Admin/MyData.js'
import SelfDescription from 'views/Admin/SelfDescription.js'
import Jobs from 'views/AdminInstitution/Jobs.js'
import Test from 'views/AdminInstitution/Test.js'
import ApplicantsTest from 'views/AdminInstitution/ApplicantsTest.js'
import Applicants from 'views/AdminInstitution/Applicants.js'
import RecoveryMailApplicants from 'views/Admin/RecoveryMailApplicants.js'
import RecoveryMailAcounnts from 'views/Admin/RecoveryMailAcounnts.js'
import GeneratePassTest from 'views/AdminInstitution/GeneratePassTest.js'
import Gestores from 'views/AdminInstitution/Gestores.js'
import Administradores from 'views/AdminInstitution/Administradores.js'
import JobsPsychometrics from 'views/AdminInstitution/JobsPsychometrics.js'
import JobsApplicantPass from 'views/AdminInstitution/JobsApplicantPass.js'
import StatusJobsPsychometrics from 'views/AdminInstitution/StatusJobsPsychometrics.js'
import ReportindIvidualFactors from 'views/AdminInstitution/ReportindIvidualFactors.js'
import ReportGeneralFactors from 'views/AdminInstitution/ReportGeneralFactors.js'
import MyAccount from 'views/Admin/MyAccount.js'
import ReportAuditUserSession from 'views/AdminInstitution/ReportAuditUserSession'

/* ############################################################# */

import logo from 'assets/img/logomain.png'

const useStyles = makeStyles(styles)

export default function Admin({ ...rest }) {
  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [url, setUrl] = useState('/dashboard')
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [alert] = React.useState({
    loggedIn: localStorage.getItem('Session') === null ? false : true,
  })

  useEffect(() => {
    axios
      .get(`${urlServices}modules/user/${localStorage.getItem('id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let resoult = response.data.rows.sort(function (a, b) {
            if (a.order > b.order) {
              return 1
            }
            if (a.order < b.order) {
              return -1
            }
            // a must be equal to b
            return 0
          })

          setRows(resoult)
          const timer = setInterval(() => {
            setLoader(false)
          }, 1500)
          return () => {
            clearInterval(timer)
          }
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  // styles
  const classes = useStyles()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const urlLinksComponent = (data) => {
    setUrl(data)
  }

  if (!alert.loggedIn) {
    localStorage.clear()
    return <Redirect to="/" />
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  /* #################### ROUTES ############################# */
  const urlLinks = (() => {
    switch (url) {
      case '/applicants':
        return <Applicants />
      case '/accounts':
        return <Accounts />
      case '/states':
        return <States />
      case '/cities':
        return <Cities />
      case '/countries':
        return <Countries />
      case '/tests':
        return <Tests />
      case '/profiles':
        return <ProfilesListAdmin />
      case '/registers':
        return <MyData />
      case '/selfdescription':
        return <SelfDescription />
      case '/jobs':
        return <Jobs />
      case '/test':
        return <Test />
      case '/dashboard':
        return <Dashboard />
      case '/testresult':
        return <ApplicantsTest />
      case '/recoverymailapplicants':
        return <RecoveryMailApplicants />
      case '/recoverymailacounnts':
        return <RecoveryMailAcounnts />
      case '/generatepass':
        return <GeneratePassTest />
      case '/gestores':
        return <Gestores />
      case '/administradores':
        return <Administradores />
      case '/jobstpm':
        return <JobsPsychometrics />
      case '/jobsapplicantpass':
        return <JobsApplicantPass />
      case '/statusjobspsychometrics':
        return <StatusJobsPsychometrics />
      case '/reportindividualfactors':
        return <ReportindIvidualFactors />
      case '/reportgeneralfactors':
        return <ReportGeneralFactors />
      case '/myaccounts':
        return <MyAccount />
      case '/reportauditusersession':
        return <ReportAuditUserSession />

      default:
        return <Dashboard />
    }
  })()
  /* ########################################################### */

  return (
    <div className={classes.wrapper}>
      {loader ? (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <center>
              <Card style={{ width: 400, marginTop: 100, padding: 20 }}>
                <CardContent>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: 150, marginBottom: 50 }}
                  />
                  <br></br>
                  <CircularProgress />
                  <Typography variant="subtitle2" color="textSecondary">
                    CARGANDO DATOS...
                  </Typography>
                </CardContent>
              </Card>
            </center>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Sidebar
            routes={rows}
            logo={logo}
            // image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            url={urlLinksComponent}
            {...rest}
          />
          <div className={classes.mainPanel} ref={mainPanel}>
            <NavbarAdmin handleDrawerToggle={handleDrawerToggle} {...rest} />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            <div className={classes.content}>
              <div className={classes.container}>{urlLinks}</div>
            </div>
            <Footer />
          </div>
        </Fragment>
      )}
    </div>
  )
}
