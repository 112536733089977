import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import {
  Cancel,
  Print,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
} from '@material-ui/icons'
import axios from 'axios'
import format from 'date-fns/format'

import img from 'assets/img/loading.gif'
import undrawUser from 'assets/img/user.jpg'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableResults(props) {
  const classes = useStyles()

  const [errorServer, setErrorServer] = useState(false)
  const [loader, setLoader] = useState(true)
  const [score, setScore] = useState(0)
  const [totalScore, setTotalScore] = useState(0)
  const [rowsQuestion, setRowsQuestion] = useState([])
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [cargo, setCargo] = React.useState('')
  const [createdAt, setCreatedAt] = React.useState('')
  const [photoUser, setPhotoUser] = React.useState(null)

  let dataTest = props.id
  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication, urlWeb } = useContext(UrlServicesContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios
      .get(
        `${urlServices}tests-results/${dataTest.testId}/${dataTest.userId}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let scoreTotal = 0

          response.data.question.map((items) => {
            items.testresults.map((items2) => {
              scoreTotal += parseFloat(items2.score)
            })
          })

          setRowsQuestion(response.data.question)
          setTotalScore(response.data.totalScore)
          setScore(scoreTotal.toFixed(2))
          setCargo(response.data.testjob[0].name)
          setCreatedAt(response.data.createdAt)
          setTotalQuestions(response.data.totalQuestions)

          SearchPhotoUser()

          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setErrorServer(true)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const SearchPhotoUser = () => {
    axios
      .get(`${urlServices}tests-users/test/${dataTest.testId}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // Si los datos están en un array, puedes hacer algo como esto:
          const filteredUser = response.data.find(
            (user) => user.userId === dataTest.userId
          )

          setPhotoUser(
            filteredUser.user.testUserPhoto.length > 0
              ? filteredUser.user.testUserPhoto[0].picture
              : null
          )
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setErrorServer(true)
        }
      })
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <center>
                  <div
                    className={classes.titleCardFilter}
                    style={{ fontSize: 20 }}
                  >
                    <b>{dataTest.userNames}</b>
                    <br></br>
                    <span style={{ fontSize: 16 }}>
                      Identificación: &nbsp;&nbsp;{dataTest.userDocument}
                    </span>
                  </div>
                  {photoUser ? (
                    <img
                      src={`${urlWeb}files/tests_users_photos/${photoUser}`}
                      alt="photoUser"
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: 3,
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <img
                      src={undrawUser}
                      alt="photoUser"
                      style={{
                        width: 90,
                        objectFit: 'cover',
                      }}
                    />
                  )}
                </center>
              </Grid>
              {/*<Grid item xs={12} sm={5}>
                <div className={classes.titleFilterAction}>
                  Correo: &nbsp;&nbsp;
                  {dataTest.userEmail !== ''
                    ? dataTest.userEmail
                    : 'SIN REGISTRAR'}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.titleFilterAction}>
                  Identificación: &nbsp;&nbsp;{dataTest.userDocument}
                </div>
              </Grid>*/}
              <Grid item xs={12} sm={6}>
                <div className={classes.titleFilterAction}>
                  <span style={{ fontSize: 11 }}>Prueba:</span> &nbsp;&nbsp;
                  {dataTest.testName}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.titleFilterAction}>
                  <span style={{ fontSize: 11 }}>Puntaje total:</span>{' '}
                  &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {score + ' / ' + totalScore}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.titleFilterAction}>
                  <span style={{ fontSize: 11 }}>Fecha:</span> &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold' }}>
                    {createdAt ? (
                      format(new Date(createdAt), 'dd-MM-yyyy')
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.titleFilterAction}>
                  <span style={{ fontSize: 11 }}>Cargo:</span> &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold' }}>{cargo}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.titleFilterAction}>
                  <span style={{ fontSize: 11 }}>Preguntas:</span> &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {totalQuestions}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.titleFilterAction}>
                  <span style={{ fontSize: 11 }}>Sin respuesta:</span>{' '}
                  &nbsp;&nbsp;
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {totalQuestions - rowsQuestion.length}
                  </span>
                </div>
              </Grid>
              {localStorage.getItem('profile_id') !== '6' ? (
                <Grid item xs={12} sm={12}>
                  <div style={{ float: 'right', marginTop: 5 }}>
                    <Button
                      type="submit"
                      className={classes.buttonSubmit1}
                      href={
                        '/printresults?test=' +
                        dataTest.testId +
                        '&userID=' +
                        dataTest.userId +
                        '&userName=' +
                        dataTest.userNames +
                        '&userDocument=' +
                        dataTest.userDocument +
                        '&photoUser=' +
                        photoUser
                      }
                      startIcon={<Print style={{ fontSize: 14 }} />}
                      component="a"
                      target="_blank"
                    >
                      Imprimir
                    </Button>
                  </div>
                </Grid>
              ) : (
                ''
              )}
              {errorServer ? (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <center>
                    <div
                      style={{
                        margin: '100px 0',
                      }}
                    >
                      <img
                        src={img}
                        alt="loader"
                        style={{
                          width: 60,
                        }}
                      />
                      <br></br>
                      <b style={{ fontSize: 10 }}>Cargando resultados</b>
                    </div>
                  </center>
                </Grid>
              ) : (
                <Fragment>
                  <Grid item xs={6} sm={6}>
                    <br></br>
                    <div className={classes.cardTitle}>
                      Resultado por pregunta:
                    </div>
                  </Grid>
                  {rowsQuestion.map((row, n) => {
                    let scoreResut = 0
                    row.testresults.map((row11) => {
                      scoreResut += parseFloat(row11.score)
                    })

                    return (
                      <Grid
                        key={n}
                        item
                        xs={12}
                        sm={12}
                        style={{ paddingLeft: 20, paddingBottom: 10 }}
                      >
                        <Alert
                          variant="outlined"
                          severity="info"
                          icon={false}
                          style={{ display: 'block' }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <div className={classes.fontParagraph}>
                                <b>
                                  {n + 1 + '-. '}
                                  {row.statement}
                                </b>
                                {localStorage.getItem('profile_id') !== '6' ? (
                                  <Fragment>
                                    &nbsp;&nbsp;&nbsp;
                                    <small>
                                      (<b>Tipo:</b>&nbsp;
                                      {row.questiontype.name})
                                    </small>
                                  </Fragment>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <div style={{ float: 'right' }}>
                                <b style={{ fontSize: 12 }}>Puntaje:</b>{' '}
                                {scoreResut !== 0 ? (
                                  <Fragment>
                                    <b style={{ color: 'green', fontSize: 16 }}>
                                      {scoreResut.toFixed(2)}
                                    </b>

                                    <CheckCircle
                                      style={{
                                        color: 'green',
                                        fontSize: 22,
                                        verticalAlign: 'text-bottom',
                                        marginLeft: 3,
                                      }}
                                    />
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <b style={{ color: 'red', fontSize: 16 }}>
                                      {scoreResut.toFixed(2)}
                                    </b>
                                    <Cancel
                                      style={{
                                        color: 'red',
                                        fontSize: 22,
                                        verticalAlign: 'text-bottom',
                                        marginLeft: 3,
                                      }}
                                    />
                                  </Fragment>
                                )}
                              </div>
                              <br></br>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                            {row.testresults.map((row2, m) => {
                              return (
                                <Fragment key={m}>
                                  {row.questiontype.id === 1 ? (
                                    <Grid item xs={12} sm={12}>
                                      <div style={{ marginLeft: 5 }}>
                                        <Fragment>
                                          {row2.selectedAnswer === true &&
                                          row2.rightAnswer === true ? (
                                            <Fragment>
                                              {' '}
                                              <CheckBox
                                                style={{
                                                  verticalAlign: 'middle',
                                                  color: 'green',
                                                }}
                                              />
                                            </Fragment>
                                          ) : row2.selectedAnswer === true ? (
                                            <Fragment>
                                              <CheckBox
                                                style={{
                                                  verticalAlign: 'middle',
                                                  color: 'red',
                                                }}
                                              />
                                            </Fragment>
                                          ) : (
                                            <CheckBoxOutlineBlank
                                              style={{
                                                verticalAlign: 'middle',
                                              }}
                                            />
                                          )}
                                        </Fragment>
                                        <span
                                          style={{
                                            paddingLeft: 10,
                                            paddingRight: 8,
                                            textAlign: 'justify',
                                          }}
                                        >
                                          {' '}
                                          {row2.answer.statement}{' '}
                                        </span>
                                      </div>
                                    </Grid>
                                  ) : (
                                    <Fragment>
                                      {row.questiontype.id === 2 ? (
                                        <Fragment>
                                          <Grid item xs={10} sm={10}>
                                            <div style={{ marginLeft: 5 }}>
                                              <span
                                                style={{
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                {row2.selectedAnswer === true &&
                                                row2.rightAnswer === true ? (
                                                  <Fragment>
                                                    {' '}
                                                    <CheckBox
                                                      style={{
                                                        verticalAlign: 'middle',
                                                        color: 'green',
                                                      }}
                                                    />
                                                  </Fragment>
                                                ) : row2.selectedAnswer ===
                                                  true ? (
                                                  <Fragment>
                                                    <CheckBox
                                                      style={{
                                                        verticalAlign: 'middle',
                                                        color: 'red',
                                                      }}
                                                    />
                                                  </Fragment>
                                                ) : (
                                                  <CheckBoxOutlineBlank
                                                    style={{
                                                      verticalAlign: 'middle',
                                                    }}
                                                  />
                                                )}
                                              </span>
                                              <span
                                                style={{
                                                  paddingLeft: 10,
                                                  paddingRight: 8,
                                                  textAlign: 'justify',
                                                }}
                                              >
                                                {' '}
                                                {row2.answer.statement}{' '}
                                              </span>
                                            </div>{' '}
                                          </Grid>
                                        </Fragment>
                                      ) : (
                                        ''
                                      )}
                                    </Fragment>
                                  )}
                                </Fragment>
                              )
                            })}
                          </Grid>
                        </Alert>
                      </Grid>
                    )
                  })}
                </Fragment>
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
