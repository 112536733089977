import React, { createContext, useState } from 'react'

const UrlServicesContext = createContext()
const { Provider, Consumer } = UrlServicesContext

function UrlServicesProvider({ children }) {
  /* ### API ### */
  const [urlServices] = useState('https://api.polygraph.email/')
  //const [urlServices] = useState('http://localhost:3000/')
  //const [urlServices] = useState('http://www.polygraph179.site/')

  const [urlWeb] = useState('https://web.polygraph.email/')
  //const [urlWeb] = useState('http://localhost:3001/')
  //const [urlWeb] = useState('http://polygraph179.site/')

  /* ### PROFILE ### */
  const [urlLocalMediaProfile] = useState('https://api.polygraph.email/files/')

  /* ### LOAD SERVICES KEYAPLICATION */
  const [keyAplication] = useState('oagLwwkQAx2e6mHEKjL8')

  return (
    <Provider
      value={{
        urlServices,
        urlLocalMediaProfile,
        keyAplication,
        urlWeb,
      }}
    >
      {children}
    </Provider>
  )
}

export {
  UrlServicesProvider,
  Consumer as urlServicesConsumer,
  UrlServicesContext,
}
