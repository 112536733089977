import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'

// core components
import { Redirect } from 'react-router-dom'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Assignment, GetApp, Today } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Slide,
  Grid,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
  cardBodyStyle,
  themeCss,
} from 'assets/jss/material-dashboard-react/components/cardBodyStyle'
import { CSVLink } from 'react-csv'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Moment from 'moment'

import LoadJobsAccount from 'components/services/LoadJobsAccount'
import ExportReportGeneralJobsExcel from 'views/Print/ExportReportGeneralJobsExcel'
import PrintPDFReportGeneralJobs from 'views/Print/PrintPDFReportGeneralJobs'

import successImg from 'assets/img/success.gif'

const defaultMaterialTheme = createTheme(themeCss)

const useStyles = makeStyles(cardBodyStyle)

export default function ReportGeneralFactors(props) {
  const classes = useStyles()

  const [closePDF, setClosePDF] = useState(false)
  const [rows, setRows] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [errorSystem, setErrorSystem] = useState(false)
  const [errorMsjSystem, setErrorMsjSystem] = useState('')
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [resultTotal, setResultTotal] = useState(0)
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [csvHeaders, setCsvHeaders] = useState([
    { label: 'Candidato', key: 'name' },
    { label: 'Documento', key: 'document' },
  ])
  const [csvData, setCsvData] = useState([])
  const [MDTData, setMDTData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [disabledTo, setdisabledTo] = useState(true)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    testAccount: '',
    testAccountData: [],
    testName: '',
    testTime: '',
    testTotalScore: '',
    created: Date('now'),
    errorTestAccount: false,
    fromDate: null,
    toDate: null,
    yearFrom: null,
    yearTo: null,
  })

  const submitAdd = async (e) => {
    e.preventDefault()
    setErrorSystem(false)
    setResultTotal(0)

    // Validaciones de formulario
    if (values.testAccount === '') {
      setValues({ ...values, errorTestAccount: true })
      setMensaje('Seleccione un cargo.')
      setOpenAlert(true)
      setError('error')
      setoOpenBackdrop(false)
      return // Salir después de mostrar el error
    }

    if (values.yearFrom !== null && values.yearTo === null) {
      setValues({ ...values, errorYearTo: true })
      setMensaje('Seleccione una fecha de fin')
      setOpenAlert(true)
      setError('error')
      setoOpenBackdrop(false)
      return // Salir después de mostrar el error
    }

    setoOpenBackdrop(true)

    const dataValue = {
      jobId: values.testAccount,
      fromDate: startDate,
      toDate: endDate,
    }

    try {
      const response = await axios.post(
        `${urlServices}reports/general/factors`,
        dataValue,
        {
          headers: {
            application: keyAplication,
            Authorization: `Bearer ${keyAuthorization}`,
          },
          // timeout: 1800000, // Establece el tiempo de espera a 30 minutos (1800000 ms)
        }
      )

      if (response.status === 200) {
        const resultArray = response.data.jobUser.reduce((acc, current) => {
          const { applicantId, applicant, testsResultsTpm } = current

          if (!acc[applicantId]) {
            acc[applicantId] = { applicant, testsResultsTpm: [] }
          }

          testsResultsTpm.forEach((testResult) => {
            const existingResult = acc[applicantId].testsResultsTpm.find(
              (result) => result.factorId === testResult.factorId
            )

            if (existingResult) {
              existingResult.score = Math.max(
                existingResult.score,
                testResult.score
              )
            } else {
              acc[applicantId].testsResultsTpm.push({ ...testResult })
            }
          })

          return acc
        }, {})

        const sortedArray = Object.values(resultArray).sort((a, b) =>
          a.applicant.lastName
            .toLowerCase()
            .localeCompare(b.applicant.lastName.toLowerCase())
        )

        setRows({ ...response.data, rowsJobUser: sortedArray })
        setResultTotal(sortedArray.length)
        setoOpenBackdrop(false)
        setClosePDF(true)

        handleCSV(
          response.data.jobFactor,
          response.data.jobUser,
          response.data.jobTpm
        )
        handleMDT(
          response.data.jobFactor,
          response.data.jobUser,
          response.data.jobTpm
        )
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setTimeout(() => {
          localStorage.clear()
          setReturnLogin(true)
        }, 200)
      } else {
        console.error(error)
        setErrorSystem(true)
        setErrorMsjSystem(
          'Ocurrió un error interno durante la consulta. Ponerse en contacto con el equipo de sistemas para resolverlo.'
        )
      }
      setoOpenBackdrop(false) // Asegúrate de cerrar el backdrop en caso de error
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const changeJobsAccount = (pro) => {
    setValues({
      ...values,
      testAccount: pro ? pro.id : '',
      testName: pro ? pro.name : '',
      testTime: pro ? pro.time : '',
      testTotalScore: pro ? pro.totalScore : '',
      testAccountData: pro,
      errorTestAccount: false,
    })
  }

  const handleCSV = (jobFactors, jobUsers, job) => {
    // Unificar datos
    const unifiedData = jobUsers.reduce((acc, curr) => {
      const { applicantId, applicant, testsResultsTpm } = curr

      // Inicializar el applicant en el acumulador si no existe
      if (!acc[applicantId]) {
        acc[applicantId] = {
          applicant,
          applicantId,
          testsResultsTpm: [],
        }
      }

      // Unificar testsResultsTpm
      testsResultsTpm.forEach((testResult) => {
        const existingResult = acc[applicantId].testsResultsTpm.find(
          (result) => result.factorId === testResult.factorId
        )

        if (existingResult) {
          // Actualiza el score si es mayor
          existingResult.score = Math.max(
            existingResult.score,
            testResult.score
          )
        } else {
          // Si no existe, agregar nuevo resultado
          acc[applicantId].testsResultsTpm.push({ ...testResult })
        }
      })

      return acc
    }, {})

    const newHeaders = jobFactors.map((jobFactor) => ({
      label: jobFactor.name,
      key: jobFactor.factor.name,
    }))

    setCsvHeaders([
      ...csvHeaders,
      ...newHeaders,
      { label: 'Total', key: 'total' },
      { label: 'Equivalencia', key: 'equivalence' },
    ])

    const newData = Object.values(unifiedData).map((result) => {
      let newResult = {
        name: `${result.applicant.lastName} ${result.applicant.name}`,
        document: result.applicant.documentId,
      }

      let total = 0

      jobFactors.forEach((jf) => {
        const score = parseFloat(
          result.testsResultsTpm.find((e) => e.factorId == jf.factorId)
            ?.score || 0
        )
        total += score

        newResult = {
          ...newResult,
          [jf.factor.name]: score.toFixed(2),
        }
      })

      return {
        ...newResult,
        total: total.toFixed(2),
        equivalence: `${total.toFixed(2)}/${job.totalScore}`,
      }
    })

    setCsvData(newData)
  }

  const handleMDT = (jobFactors, jobUsers, job) => {
    // Unificar datos
    const unifiedData = jobUsers.reduce((acc, curr) => {
      const { applicantId, applicant, testsResultsTpm } = curr

      // Inicializar el applicant en el acumulador si no existe
      if (!acc[applicantId]) {
        acc[applicantId] = {
          applicant,
          applicantId,
          testsResultsTpm: [],
        }
      }

      // Unificar testsResultsTpm
      testsResultsTpm.forEach((testResult) => {
        const existingResult = acc[applicantId].testsResultsTpm.find(
          (result) => result.factorId === testResult.factorId
        )

        if (existingResult) {
          // Actualiza el score si es mayor
          existingResult.score = Math.max(
            existingResult.score,
            testResult.score
          )
        } else {
          // Si no existe, agregar nuevo resultado
          acc[applicantId].testsResultsTpm.push({ ...testResult })
        }
      })

      return acc
    }, {})

    const newHeaders = jobFactors.map((jobFactor) => ({
      label: jobFactor.name,
      key: jobFactor.factor.name,
    }))

    setCsvHeaders([
      ...csvHeaders,
      ...newHeaders,
      { label: 'Total', key: 'total' },
      { label: 'Equivalencia', key: 'equivalence' },
    ])

    const newData = Object.values(unifiedData).map((result) => {
      let newResult = {
        name: `${result.applicant.lastName} ${result.applicant.name}`,
        document: result.applicant.documentId,
      }

      let total = 0
      let total_factor = 0

      if (jobFactors.length > 0) {
        jobFactors.forEach((jf) => {
          const score = parseFloat(
            result.testsResultsTpm.find((e) => e.factorId === jf.factorId)
              ?.score || 0
          )
          total += score

          // Solo sumar a total_factor si el código de prueba es 'CEQ'
          if (jf.test.code === 'CEQ') {
            total_factor += score
          }

          newResult = {
            ...newResult,
            [jf.name.toLowerCase()]: score.toFixed(2),
            [jf.test.code]:
              jf.test.code === 'CEQ'
                ? total_factor.toFixed(2)
                : score.toFixed(2), // Solo mostrar total_factor si es CEQ
          }
        })
      }

      return {
        ...newResult,
        total: total.toFixed(2),
        equivalence: `${total.toFixed(2)}/${job.totalScore}`,
      }
    })

    setMDTData(newData)
  }

  const handleDateChangeFrom = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearFrom: event,
        yearToMinDate: event,
        yearTo: null,
        errorYearFrom: false,
        errorYearTo: false,
      })

      setStartDate(Moment(event).format('YYYY-MM-DD'))
      setdisabledTo(false)
    } else {
      setValues({
        ...values,
        yearFrom: null,
        yearTo: null,
        errorYearFrom: false,
        errorYearTo: false,
      })
      setStartDate(null)
      setEndDate(null)
      setdisabledTo(true)
    }
  }

  const handleDateChangeTo = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearTo: event,
        errorYearTo: false,
      })

      setEndDate(Moment(event).format('YYYY-MM-DD'))
    } else {
      setValues({
        ...values,
        yearTo: null,
        errorYearFrom: false,
      })
      setEndDate(null)
    }
  }

  const handleClosePDF = () => () => {
    setClosePDF(false)
  }

  const handleOpenPDF = () => () => {
    setClosePDF(true)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div className={classes.cardTitleWhite}>
                <Assignment className={classes.iconWhite} /> Reporte general por
                cargo
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  {errorSystem && (
                    <Alert severity="error">{errorMsjSystem}</Alert>
                  )}
                </GridItem>
                <GridItem xs={12} md={10}>
                  <LoadJobsAccount
                    refresh={changeJobsAccount}
                    value={values.testAccountData}
                    error={values.errorTestAccount}
                    all={true}
                  />
                </GridItem>
                <GridItem xs={12} md={4}>
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <KeyboardDatePicker
                        id="date-picker-dialog"
                        label="Desde"
                        onChange={handleDateChangeFrom}
                        value={values.yearFrom}
                        format="yyyy-MM-dd"
                        // views={["year"]}
                        animateYearScrolling={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        variant="inline"
                        keyboardIcon={<Today className={classes.iconTheme} />}
                        autoComplete="off"
                        //InputProps={{ readOnly: true }}
                        autoOk
                        //minDate={new Date()}
                        minDateMessage={false}
                        maxDateMessage={false}
                        error={values.errorYearFrom}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <KeyboardDatePicker
                        id="date-picker-dialog"
                        label="Hasta"
                        onChange={handleDateChangeTo}
                        value={values.yearTo}
                        format="yyyy-MM-dd"
                        // views={["year"]}
                        animateYearScrolling={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        variant="inline"
                        keyboardIcon={
                          <Today
                            className={
                              disabledTo ? classes.iconWhite : classes.iconTheme
                            }
                          />
                        }
                        minDate={values.yearToMinDate}
                        autoComplete="off"
                        //InputProps={{ readOnly: true }}
                        autoOk
                        minDateMessage={false}
                        maxDateMessage={false}
                        disabled={disabledTo}
                        error={values.errorYearTo}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <br></br>
                  <Button
                    className={classes.buttonLeft}
                    onClick={submitAdd}
                    startIcon={<Search />}
                  >
                    Buscar
                  </Button>
                </GridItem>
              </GridContainer>

              {resultTotal > 0 ? (
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <br></br>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                              backgroundColor: '#F2F2F2',
                            }}
                          >
                            Candidato{resultTotal > 1 ? 's' : ''}: {resultTotal}{' '}
                            registro
                            {resultTotal > 1 ? 's' : ''}
                            &nbsp;&nbsp;
                            <Button
                              className={classes.buttonSubmit1}
                              startIcon={<GetApp style={{ fontSize: 18 }} />}
                              onClick={handleOpenPDF()}
                            >
                              PDF
                            </Button>
                            &nbsp;&nbsp;
                            <CSVLink
                              data={csvData}
                              headers={csvHeaders}
                              filename={'reporte_general_por_cargo.csv'}
                            >
                              <Button
                                type="button"
                                className={classes.buttonSubmit1}
                                startIcon={<GetApp style={{ fontSize: 18 }} />}
                              >
                                CSV
                              </Button>
                            </CSVLink>
                            &nbsp;&nbsp;
                            <ExportReportGeneralJobsExcel
                              excelData={MDTData}
                              fileName={'Exportar excel para el MDT'}
                              className={classes.buttonSubmit1}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <br></br>
                    <PrintPDFReportGeneralJobs
                      data={rows}
                      exit={handleClosePDF()}
                      open={closePDF}
                    />
                    <center>
                      <img
                        src={successImg}
                        alt="successPassword"
                        style={{
                          width: '120px',
                        }}
                      />
                      <br></br>
                      <b style={{ fontSize: 18, marginBottom: 20 }}>
                        Consulta realizada con éxito
                      </b>
                    </center>
                  </Grid>
                </Grid>
              ) : (
                <div
                  style={{
                    color: '#C5C5C5',
                    padding: '60px 0',
                    fontWeight: 'bold',
                  }}
                >
                  <center> No hay resultados </center>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Backdrop
        style={{ zIndex: '9999', color: '#FFFFFF' }}
        open={openBackdrop}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <center>
              <CircularProgress color="inherit" />
            </center>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ fontSize: 14, color: 'white', fontWeight: 'bold' }}
          >
            <center>
              <span style={{ fontSize: 16 }}>No cierre la ventana</span>
              <br></br>(Esto puede tardar unos minutos dependiendo el número de
              postulantes)
            </center>
          </GridItem>
        </GridContainer>
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error === 'error' ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
    </Fragment>
  )
}
